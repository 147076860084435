exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-apmokejimas-sekmingas-js": () => import("./../../../src/pages/apmokejimas-sekmingas.js" /* webpackChunkName: "component---src-pages-apmokejimas-sekmingas-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-code-template-js": () => import("./../../../src/templates/code-template.js" /* webpackChunkName: "component---src-templates-code-template-js" */),
  "component---src-templates-page-template-js": () => import("./../../../src/templates/page-template.js" /* webpackChunkName: "component---src-templates-page-template-js" */),
  "component---src-templates-post-template-js": () => import("./../../../src/templates/post-template.js" /* webpackChunkName: "component---src-templates-post-template-js" */),
  "component---src-templates-product-template-js": () => import("./../../../src/templates/product-template.js" /* webpackChunkName: "component---src-templates-product-template-js" */)
}

